import { toast } from "react-toastify";
import { apiPost } from "../../Utils/ApiHelper";
import { ADD_TO_CART, CHECKOUT, ORDER } from "../Types";

export default function cartReducer(state, action) {
  switch (action.type) {
    case ADD_TO_CART:
      const item = state.find((x) => x.nomineeId === action.payload.id);
      if (!item) {
        return [
          ...state,
          {
            nomineeId: action.payload.id,
            votes: parseInt(action.payload.votes),
            name: action.payload.name,
          },
        ];
      }

      state[state.indexOf(item)].votes = parseInt(action.payload.votes);
      return state;
    case CHECKOUT:
      const total = state.reduce((total, item) => total + item.votes, 0) * 50;
      const order = {
        amount: total,
        votes: state,
        eventSlug: action.payload.eventSlug,
        email: action.payload.email
      };
      window.localStorage.setItem("order", JSON.stringify(order));
      apiPost("Payment", order, {}).then((res) => {
        window.location.href = res.data.data.authorizationUrl;
      }).catch((err) => {
        toast.error(err.message, { position: "top-right" });
        window.location.href = `/${order.eventSlug}`;
      });
      break;
    case ORDER:
        apiPost(
          `order/${action.payload.eventSlug}/${action.payload.paymentRef}`,
          {},
          {},
          false
        ).then(
          (res) => {
            toast.success(res.data.message, { position: "top-right" });
            window.location.href = "/";
          },
          (err) => {
            toast.error(err.response.data.message, { position: "top-right" });
            window.location.href = "/";
          }
        );
      break;
    default:
      return state;
  }
}
