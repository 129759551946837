import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiGet, apiPatch } from "../Utils/ApiHelper";
import Modal from "./Modal";

export const EditNominee = ({ id, closeModal }) => {
  const [nominee, setNominee] = useState({
    name: "",
    image: "",
  });
  useEffect(() => {
    apiGet(`nominee/${id}`, {}, false).then((res) => {
      setNominee(res.data.data);
    });
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", nominee.name);
    formData.append("image", nominee.image, nominee.image.name);
    apiPatch(`nominee/${id}`, formData, {}).then(
      (res) => {
        toast.success(res.data.message, { position: "top-right" });
        closeModal();
      },
      (err) => {
        toast.error(err.response.message, { position: "top-right" });
      }
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="name">Nominee Name</label>
        <input
          type="text"
          className="form-control"
          name="nominee"
          value={nominee.name}
          placeholder="Nominee Name"
          required
          onChange={(e) =>
            setNominee((prev) => ({ ...prev, name: e.target.value }))
          }
        />
      </div>
      <div className="form-group">
        <label htmlFor="name">Change Image</label>
        <input
          type="file"
          name="image"
          className="form-control"
          onChange={(e) =>
            setNominee((prev) => ({ ...prev, image: e.target.files[0] }))
          }
        />
      </div>
      <button type="submit" className="btn btn-block btn-primary">
        Submit
      </button>
    </form>
  );
};


export const EditButton = ({ id, name }) => {
  
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <button
        className="btn btn-info btn-sm mr-2"
        onClick={() => setShowModal(true)}
      >
        Edit
      </button>

      <Modal
        title={`Update ${name}`}
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
      >
        <EditNominee id={id} closeModal={() => setShowModal(false)} />
      </Modal>
    </div>
  );
}