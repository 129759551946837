import React from 'react'
import { Link } from 'react-router-dom'

export default function SideMenu() {
  return (
    <ul className="nav flex-column">
      <li className="nav-item">
        <Link
          className="nav-link"
          activeClassName="active"
          to="/admin/dashboard"
        >
          Dashboard
        </Link>
        <Link
          className="nav-link"
          activeClassName="active"
          to="/admin/events"
        >
          Events
        </Link>
        <Link
          className="nav-link"
          activeClassName="active"
          to="/admin/payments"
        >
          Payments
        </Link>
        <Link
          className="nav-link"
          activeClassName="active"
          to="/logout"
        >
          Log Out
        </Link>
      </li>
    </ul>
  );
}
