import React from 'react'
import { Outlet } from 'react-router-dom'
import Nav from '../Component/Nav'
import SideMenu from '../Component/SideMenu'

export default function AdminLayout() {
  return (
    <div className="container-fluid">
      <Nav/>
      <div className="row">
        <div className="col-md-2">
          <SideMenu/>
        </div>
        <div className="col-md-10">
          <Outlet/>
        </div>
      </div>
    </div>
  )
}
