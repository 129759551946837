import React, { useState } from "react";
import styled from "styled-components";
import { useCart } from "../Context/Cart/CartState";
import Modal from "./Modal";

const NomineeArea = styled.div`
  padding: 10px;
  border-radius: 15px;
  width: 100;
  display: flex;
  align-items: center;
  margin: 15px 0;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`;

export const Avatar = styled.img`
  width: 65px;
  height: 65px;
  border-radius: 100%;
  background: #c4c4c4;
  margin-right: 10px;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const NameArea = styled.p`
  font-weigth: 600;
  font-size: 1.125rem;
`;

export const VoteButton = styled.button`
  background: #f1b209;
  color: #ffffff;
  border-radius: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  &:hover {
    color: #ffffff;
    background: #f2a20c;
  }
`;

export default function NomineeCard({ name, imageUrl, id }) {
  const [openModal, setOpenModal] = useState(false);
  const [votes, setVotes] = useState();
  const { addToCart } = useCart();

  const handleSubmit = (e) => {
    e.preventDefault();
    addToCart(id, votes, name);
    toggleModal();
  };


  const toggleModal = () => setOpenModal(!openModal);
  return (
    <>
      <NomineeArea>
        <Avatar src={imageUrl} alt={name} />
        <Info>
          <NameArea>{name}</NameArea>
          <VoteButton
            className="btn"
            type="button"
            onClick={() => toggleModal()}
          >
            Vote
          </VoteButton>
        </Info>
      </NomineeArea>

      <Modal title={name} isOpen={openModal} closeModal={toggleModal}>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              className="form-control"
              type="number"
              placeholder="Number of Votes"
              defaultValue={votes}
              onChange={(e) => setVotes(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn">
            Save
          </button>
        </form>
      </Modal>
    </>
  );
}