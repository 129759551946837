import React, { useEffect } from 'react'

const Logout = () => {
  useEffect(() => {
    window.localStorage.clear();
    window.location.href = '/login';
  }, [])
  
  return (
    <div className="container-fluid">
      <h3 className='text-align-center'>Logging out...</h3>
    </div>
  );
}

export default Logout