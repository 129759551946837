import React, { useEffect, useState } from "react";
import { apiGet } from "../Utils/ApiHelper";
import styled from "styled-components";
import Category, { CategoryTitle } from "../Component/Category";
import Summary from "../Component/Summary";
import OrderSumamry from "../Component/OrderSumamry";
import { useViewport } from "../Context/Viewport/Viewport";
import { useParams } from "react-router-dom";
import Nav from "../Component/Nav";

const Main = styled.main`
  padding: 10px;
  height: ${({ height }) => height}px;
  overflow-y: auto;
`;

const Container = styled.div`
  position: relative;
  height: 100vh;
`;

// const Alert = styled.div`
//   position: absolute;
//   top: 5px;
//   left: 50%;
//   transform: translateX(-50%);
//   width: auto;
// `;

const SummaryArea = styled.div`
  width: 100;
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  margin: 15px 0;
`;

export default function Vote() {
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);
  const { eventSlug } = useParams();

  const { height, width } = useViewport();

  const getEvent = async () => {
    await apiGet(`event/${eventSlug}`, {}, false).then((res) => {
      setEvent(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getEvent();
  }, []);

  const mainHeight = width <= 820 ? height - 265 : height - 120;

  return (
    <Container>
      <Nav />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <Main height={mainHeight}>
              {loading ? (
                <p>Loading...</p>
              ) : (
                event?.categories.map((category) => (
                  <Category key={category.id} category={category} />
                ))
              )}
            </Main>
          </div>
          <div
            className="col-md-6"
            style={{ display: width <= 820 ? "none" : "block" }}
          >
            <CategoryTitle>Order Summary</CategoryTitle>
            <SummaryArea>
              <OrderSumamry />
            </SummaryArea>
          </div>
        </div>
      </div>
      <Summary />
    </Container>
  );
}
