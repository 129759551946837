import React from "react";
import styled from "styled-components";
import NomineeCard from "./NomineeCard";

export const CategoryTitle = styled.h2`
  font-weight: 700;
  font-size: 1.125rem;
`;

export default function Category({ category: { name, nominees }, updateCart }) {
  return (
    <div>
      <CategoryTitle>{name}</CategoryTitle>
      {nominees.map((nominee) => (
        <NomineeCard
          key={nominee.id}
          name={nominee.name}
          imageUrl={nominee.imageUrl}
          id={nominee.id}
          updateCart={(id, votes) => updateCart(id, votes)}
        />
      ))}
    </div>
  );
}
