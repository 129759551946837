import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useCart } from "../Context/Cart/CartState";

export default function OrderConfirmation() {
  const [searchParams] = useSearchParams();
  const { eventSlug } = useParams();

  const { order } = useCart();

  const trxref = searchParams.get("trxref");

  useEffect(() => {
    if (trxref) order(`${eventSlug}`, trxref);
  }, [order, trxref, eventSlug]);

  return (
    <div>
      <center>
        <h1>Voting...</h1>
      </center>
    </div>
  );
}
