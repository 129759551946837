import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiGet } from "../Utils/ApiHelper";

export default function Payment() {
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    apiGet("payment", {}, true).then(
      (res) => {
        setPayments(res.data.data);
      },
      (err) => {
        toast.error(err.response.message, { position: "top-right" });
      }
    );
  }, []);

  return (
    <div className="m-4">
      <h3>Payments</h3>
      <table className="table">
        <thead>
          <tr>
            <th className="align-items-center">Date</th>
            <th>Payment Ref</th>
            <th className="align-items-center">Amount</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr key={payment.id}>
              <td>{new Date(payment.createdAt).toLocaleString("en-NG")}</td>
              <td>{payment.paymentRef}</td>
              <td>
                {payment.amount.toLocaleString("en-NG", {
                  style: "currency",
                  currency: "NGN",
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
