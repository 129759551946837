import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import CartState from './Context/Cart/CartState';
import { ViewportProvider } from './Context/Viewport/Viewport';
import "react-toastify/dist/ReactToastify.css";
import Vote from './Pages/Vote';
import Home from './Pages/Home';
import OrderConfirmation from './Component/OrderConfirmation';
import AdminLayout from './Layouts/AdminLayout';
import Dashboard from './Pages/Dashboard';
import Payment from './Pages/Payment';
import Login from './Pages/Login';
import AuthState from './Context/auth/AuthState';
import ProtectedRoute from './Context/auth/ProtectedRoute';
import Events from './Pages/Events';
import Event from './Pages/Event';
import Logout from './Component/Logout';

function App() {
  return (
    <div>
      <ViewportProvider>
        <ToastContainer />
        <AuthState>
          <CartState>
            <Router>
              <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/login" exact element={<Login />} />
                <Route path="/logout" exact element={<Logout />} />
                <Route path="/:eventSlug" exact element={<Vote />} />
                <Route
                  path="/:eventSlug/confirm-order"
                  exact
                  element={<OrderConfirmation />}
                />
                <Route
                  path="/admin"
                  exact
                  element={
                    <ProtectedRoute>
                      <AdminLayout />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path="dashboard"
                    exact
                    element={
                      <ProtectedRoute>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="events"
                    exact
                    element={
                      <ProtectedRoute>
                        <Events />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="events/:slug"
                    exact
                    element={
                      <ProtectedRoute>
                        <Event />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="payments"
                    exact
                    element={
                      <ProtectedRoute>
                        <Payment />
                      </ProtectedRoute>
                    }
                  />
                </Route>
              </Routes>
            </Router>
          </CartState>
          <a
            href="https://wa.me/message/QBEXMCFGZZK3M1"
            className="float"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-whatsapp my-float"></i>
          </a>
        </AuthState>
      </ViewportProvider>
    </div>
  );
}

export default App;
