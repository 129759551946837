import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useCart } from "../Context/Cart/CartState";
import { CategoryTitle } from "./Category";
import { VoteButton } from "./NomineeCard";

export default function OrderSumamry() {
  const [email, setEmail] = useState("");
  const { cart, checkout } = useCart();
  const {eventSlug} = useParams();
  
  const total = cart.reduce((t, c) => t + c.votes, 0) * 50;
  const handleSubmit = (e) => {
    e.preventDefault();
    checkout(email, eventSlug);
  };

  return (
    <div>
      {total ? (
        <>
          <table className="table">
            <thead>
              <tr>
                <th>Nominee Name</th>
                <th>Votes</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((item) => (
                <tr key={item.nomineeId}>
                  <td>{item.name}</td>
                  <td>{item.votes}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Enter your email to confirm vote</label>
              <input
                type="email"
                name="email"
                className="form-control"
                required
                placeholder="Email Address"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <VoteButton type="submit" className="btn btn-block">
              Pay{" "}
              {total.toLocaleString("en-NG", {
                style: "currency",
                currency: "NGN",
              })}
            </VoteButton>
          </form>
        </>
      ) : (
        <CategoryTitle>
          <center>Vote a nominee to see summary</center>
        </CategoryTitle>
      )}
    </div>
  );
}
