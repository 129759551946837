import React, { useState } from "react";
import { toast } from "react-toastify";
import { apiPost } from "../../Utils/ApiHelper";
import { EditButton } from "../Edit";
import Modal from "../Modal";
import { Avatar } from "../NomineeCard";

export default function Category({ category }) {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formDetails, setFormDetails] = useState({
    name: "",
    image: "",
  });

  const toggleModal = () => {
    console.log("here");
    setOpenModal(!openModal);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", formDetails.name);
    formData.append("image", formDetails.image, formDetails.image.name);
    formData.append("categoryId", category.id)
    apiPost(`category/add-nominee`, formData, {}).then(
      (res) => {
        toast.success(res.data.message, { position: "top-right" });
        toggleModal();
      },
      (err) => {
        toast.error(err.response.message, { position: "top-right" });
      }
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="col-md-5 mr-3 mb-3" key={category.id}>
      <div className="row justify-content-between align-items-center">
        <h4>{category.name}</h4>
        <button className="btn btn-primary" onClick={() => toggleModal()}>
          Add Nominee
        </button>
        <Modal title={`Add Nominee - ${category.name}`} isOpen={openModal} closeModal={toggleModal}>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Nominee Name"
                name="name"
                value={formDetails.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="file"
                placeholder="Nominee Picture"
                name="image"
                onChange={(e) =>
                  setFormDetails((prev) => ({
                    ...prev,
                    image: e.target.files[0],
                  }))
                }
                required
              />
            </div>
            <button type="submit" className="btn btn-primary" disabled={loading}>
              Save
            </button>
          </form>
        </Modal>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Nominees</th>
            <th className="text-center">Votes</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {category?.nominees?.map((nominee) => (
            <tr key={nominee?.id}>
              <td>
                <Avatar src={nominee.imageUrl} alt={nominee.name} />
                {nominee?.name}
              </td>
              <td className="text-center">{nominee?.votes}</td>
              <td>
                <div className="d-flex justify-content-center">
                  <EditButton id={nominee.id} name={nominee.name} />
                  {/* <button className="btn btn-danger btn-sm">
                          Delete
                        </button> */}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
