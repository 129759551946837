import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LogoImg from "../assets/bma-logo.png";
import LifeStyle64 from "../assets/64-lifestyle.png";
import NCDMB from "../assets/NCDMB.jpg";
import MNL from "../assets/mnl.png";
import spinet from "../assets/spinet.jpg";
import sdg from "../assets/sdg.jpg";
import bayelsa from "../assets/bayelsa1.jpg";
import sqilful from "../assets/sqilful.jpg";
import cxi from "../assets/cxi.jpg";
import zoraz from "../assets/zoraz.jpg";
import silverflame from "../assets/silverflame.png";
import Nav from "../Component/Nav";

const Hero = styled.section`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeroSection = styled.div`
  text-align: center;
  width: 90%;
  max-width: 600px;
`;

const H1 = styled.h1`
  font-size: 2.5em;
`;

const VoteButton = styled(Link)`
  background: #f1b209;
  color: #ffffff;
  width: 150px;
  border-radius: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  &:hover {
    color: #ffffff;
    background: #f2a20c;
  }
`;

const PartnerSection = styled.section`
  text-align: center;
  margin: 25px 0;
`;

const Partners = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const Partner = styled.img`
  height: 120px;
  width: auto;
  margin: 10px;
`;
export default function Home() {
  return (
    <div className="container-fluid">
      <Nav/>
      <Hero>
        <HeroSection>
          <H1>Bayelsa Media Awards</H1>
          <p className="lead">
            The Bayelsa Media Awards is an award programme that recognizes
            excellence in the media profession & entertainment sector in Bayelsa
            State & the nation at large.
          </p>
          <VoteButton className="btn btn-lg" to="/BMA-23">
            Vote
          </VoteButton>
        </HeroSection>
      </Hero>
      <PartnerSection>
        <H1>Our Partners</H1>
        <Partners>
          <Partner src={NCDMB} alt="NCDMB" />
          <Partner src={MNL} alt="MNL" />
          <Partner src={spinet} alt="Spinet" />
          <Partner src={bayelsa} alt="BYSG" />
          <Partner src={sdg} alt="SDG" />
          <Partner src={cxi} alt="cxi" />
          <Partner src={zoraz} alt="zoraz" />
        </Partners>
      </PartnerSection>
      <div className="container my-5" id="about">
        <center>
          <H1 className="mb-4">About BMA</H1>
        </center>
        <div className="row align-items-center">
          <div className="col-md-6 mb-4">
            <img
              src={LogoImg}
              alt="Bayelsa Media Awards"
              className="img-fluid"
            />
          </div>
          <div className="col-md-6">
            <p>
              The Bayelsa Media Awards is an award programme that recognizes
              excellence in the media profession and entertainment sector in
              Bayelsa State and the nation at large. It is the most authentic
              and widely acclaimed media cum entertainment awards ceremony in
              whole of southern Nigeria, with the assemblage of successful media
              practitioners from across the nation coming together to celebrate
              excellence in broadcasting and other entertainment arts.
            </p>
            <p>
              Bayelsa Media Awards started in 2013 and have been able to host
              eight successful editions and has succeeded in consciously driving
              excellence in broadcasting, media activities, advertorials and
              other arts of entertainment. Media practitioners now put BMA
              before financial profit, as it has been certified to be profitable
              in the long run beyond accolades.
            </p>
            <p>
              There is a compelling need to invest in the media sector so as to
              fully exploit the opportunities that abound in it and which
              investors can take advantage of such as brand abassadorship, media
              visibility, brand exhibition programmes to mention but a few. The
              Bayelsa Media Awards sufficiently provides these platform to
              investors.
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mx-5">
          <h3 className="mr-4">Powered by:{" "}</h3>
          <img className="img-fluid" width="150px" src={sqilful} alt="Sqilful Konseptz"/>
        </div>
      </div>
    </div>
  );
}
