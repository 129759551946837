import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import LogoImg from "../assets/bma-logo.png";


const Navbrand = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  border-bottom: 1px solid #f1b209;
`;

const Logo = styled.img`
  height: 65px;
  width: auto;
`;

export default function Nav() {
  return (
    <div className="container-fluid">
      <Navbrand>
        <Link to="/">
          <Logo src={LogoImg} alt="Bayelsa Media Awards Logo" />
        </Link>
      </Navbrand>
    </div>
  );
}
