import React, { useState } from "react";
import styled from "styled-components";
import { useCart } from "../Context/Cart/CartState";
import { useViewport } from "../Context/Viewport/Viewport";
import Modal from "./Modal";
import { VoteButton } from "./NomineeCard";
import OrderSumamry from "./OrderSumamry";

const SummaryArea = styled.div`
  height: 150px;
  background: #c4c4c4;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  display: ${({width}) => width > 820 ? "none" : "block"}
`;

export default function Summary() {
  const { cart } = useCart();
  const no = cart.reduce((t, c) => t + parseInt(c.votes), 0);
  const [showSummary, setShowSummary] = useState(false);

  const toggleSummary = () => setShowSummary(!showSummary);

  const { width } = useViewport();

  return (
    <>
      <SummaryArea width={width}>
        <div className="container-fluid">
          <h6>Summary</h6>
          <div className="d-flex justify-content-between mb-4">
            <div>{no} Votes</div>
            <div>
              {(no * 50).toLocaleString("en-NG", {
                style: "currency",
                currency: "NGN",
              })}
            </div>
          </div>
          <VoteButton
            className="btn btn-block"
            onClick={() => toggleSummary()}
          >
            Checkout
          </VoteButton>
        </div>
      </SummaryArea>
      <Modal
        title="Order Summary"
        isOpen={showSummary}
        closeModal={toggleSummary}
      >
        <OrderSumamry />
      </Modal>
    </>
  );
}
