import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { EditButton } from "../Component/Edit";
import { Avatar } from "../Component/NomineeCard";
import { apiGet } from "../Utils/ApiHelper";

export default function Dashboard() {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [singleEvent, setSingleEvent] = useState({});
  const { categories } = singleEvent;
  const getEvents = async () => {
    await apiGet("event", {}, false).then(
      (res) => {
        setEvents(res.data.data);
      },
      (err) => {
        toast.error(err.response.data.message);
      }
    );
  };
  useEffect(() => {
    getEvents();
  }, []);

  const updateEvent = (e) => {
    setSelectedEvent(e.target.value);
    setSingleEvent(events.find((x) => x.id === e.target.value));
  };

  const eventList = events.map((event) => (
    <option value={event.id} key={event.id}>
      {event.name}
    </option>
  ));

  return (
    <div className="m-3">
      <div className="form-group col-md-4 mb-4">
        <select
          className="form-control"
          value={selectedEvent}
          onChange={updateEvent}
        >
          <option value="" disabled>
            Select Event
          </option>
          {eventList}
        </select>
      </div>
      <div className="row">
        {categories?.map((category) => (
          <div className="col-md-6" key={category.id}>
            <h4>{category.name}</h4>
            <table className="table">
              <thead>
                <tr>
                  <th>Nominees</th>
                  <th className="text-center">Votes</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {category?.nominees?.map((nominee) => (
                  <tr key={nominee?.id}>
                    <td>
                      <Avatar src={nominee.imageUrl} alt={nominee.name} />
                      {nominee?.name}
                    </td>
                    <td className="text-center">{nominee?.votes}</td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <EditButton id={nominee.id} name={nominee.name}/>
                        {/* <button className="btn btn-danger btn-sm">
                          Delete
                        </button> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
}
