import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Category from '../Component/Admin/Category';
import Modal from '../Component/Modal';
import { apiGet, apiPost } from '../Utils/ApiHelper';

export default function Event() {
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState({
    name: "",
    description: "",
  });

  const { slug } = useParams();

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const getEvent = async () => {
    setLoading(true);
    await apiGet(`event/${slug}`, {}, false).then(
      (res) => {
        console.log(res.data.data);
        setEvent(res.data.data);
        setLoading(false);
      },
      (err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      }
    );
  };

  const handleCategorySubmit = (e) => {
    e.preventDefault();
    apiPost(`category/create/${slug}`, categoryDetails, {}, true).then((res) => {
      toast.success(res.data.message, { position: "top-right" });
      toggleModal();
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    getEvent();
  }, []);

  if (loading) {
    return (
      <div className="m-3">
        <h3 className="text-center display-4">Loading...</h3>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <h1 className="display-4">{event.name}</h1>
        <button className="btn btn-primary" onClick={() => toggleModal()}>
          Add Category
        </button>
        <Modal title="Add Category" isOpen={openModal} closeModal={toggleModal}>
          <form onSubmit={handleCategorySubmit}>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Category Name"
                name="name"
                value={categoryDetails.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Category Description"
                name="description"
                value={categoryDetails.description}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </form>
        </Modal>
      </div>
      <h2>Categories</h2>
      <div className="row">
        {event.categories?.map((category) => (
          <Category category={category} />
        ))}
      </div>
    </div>
  );
}
