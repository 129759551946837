import React, { useReducer } from "react";
import { ADD_TO_CART, CHECKOUT, ORDER } from "../Types";
import CartContext from "./CartContext";
import cartReducer from "./CartReducer";

const CartState = ({ children }) => {
  const initialState = [];

  const [state, dispatch] = useReducer(cartReducer, initialState);

  const addToCart = (id, votes, name) => {
    dispatch({
      type: ADD_TO_CART,
      payload: { id, votes, name },
    });
  };

  const checkout = (email, eventSlug) => {
    dispatch({
      type: CHECKOUT,
      payload: { email, eventSlug },
    });
  };

  const order = async (eventSlug, paymentRef) => {
    dispatch({
      type: ORDER,
      payload: { eventSlug, paymentRef },
    });
  };

  return (
    <CartContext.Provider value={{ addToCart, checkout, order, cart: state }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = React.useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

export default CartState;
