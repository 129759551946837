import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../assets/bma-logo.png";
import Modal from "../Component/Modal";
import { apiGet, apiPost } from "../Utils/ApiHelper";

export default function Events() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formDetails, setFormDetails] = useState({
    name: "",
    slug: "",
    votePrice: "",
  });

  const toggleModal = () => {
    console.log("here");
    setOpenModal(!openModal);
  };

  const getEvents = async () => {
    setLoading(true);
    await apiGet("event", {}, false).then(
      (res) => {
        console.log(res.data.data);
        setEvents(res.data.data);
        setLoading(false);
      },
      (err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    apiPost("Event", formDetails, {}, true).then((res) => {
      toast.success(res.data.message, { position: "top-right" });
      toggleModal();
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    getEvents();
  }, []);

  if (loading) {
    return (
      <div className="m-3">
        <h3 className="text-center display-4">Loading...</h3>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <h1 className="display-4">Events</h1>
        <button className="btn btn-primary" onClick={() => toggleModal()}>
          Add Event
        </button>
        <Modal title="Add Event" isOpen={openModal} closeModal={toggleModal}>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Title"
                name="name"
                value={formDetails.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Event Slug"
                name="slug"
                value={formDetails.slug}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="number"
                placeholder="Vote Price"
                name="votePrice"
                value={formDetails.votePrice}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </form>
        </Modal>
      </div>
      <div className="row mt-4">
        {events.map((event) => (
          <div className="col-md-4" key={event.id}>
            <div className="card mb-2">
              <img src={logo} className="card-img-top p-3" alt="BMA Logo" />
              <div className="card-body">
                <h5 className="card-title">
                  {event.name}
                  <br />
                  <small>
                    Created:{" "}
                    {new Date(event.createdAt).toLocaleDateString("en-NG")}
                  </small>
                </h5>
                <p className="card-text">
                  Categories: {event.categories.length}
                </p>
                <p className="card-text">
                  Vote Price:{" "}
                  {event.votePrice.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })}
                </p>
              </div>
              <div className="card-footer">
                <Link
                  to={`/admin/events/${event.slug}`}
                  className="btn btn-primary"
                >
                  View Event
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
