import { toast } from 'react-toastify';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOADING,
  LOGOUT,
} from '../Types';

export default function authReducer(state, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.data.token);
      const user = {
        firstName: action.payload.data.firstName,
        lastName: action.payload.data.lastName,
        email: action.payload.data.email,
        id: action.payload.data.id,
      };
      localStorage.setItem('user', JSON.stringify(user));
      toast.success(action.payload.message, {
        position: 'top-right',
      });
      window.location.href = '/admin/dashboard';
      
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LOGIN_FAIL:
      toast.error(action.payload.message || action.payload);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/';
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    default:
      return state;
  }
}
